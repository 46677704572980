<template>
    <div>
        <el-dialog title="" :visible.sync="show" width="30%">
            <Show @form="checkf" />
            <span slot="footer">
                <el-button @click="show = false">取消</el-button>
                <el-button type="primary" :loading="btn" @click="download">导出</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Show from "./show.vue";
export default {
    data() {
        return {
            show: false,
            form3: {},
            btn: false,
        };
    },
    components: {
        Show,
    },
    watch: {
        form3(newValue, oldValue) {
            this.$emit("update:form", newValue);
        },
    },
    methods: {
        checkf(val) {
            this.form3 = val;
        },
        init() {
            this.show = true;
        },
        download() {
            this.btn = true;
            this.$emit("download", this.form3, this.done);
        },
        done() {
            this.btn = false;
            this.show = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
